import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import Loading from "@/components/layouts/common/Loading";
import CareerForm from "@/components/layouts/profile/CareerForm";
import useControlBlowserBack from "@/hooks/useControlBlowserBack";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { CareerSelector } from "@/stores/selectors/CareerSelector";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { apiDelete } from "@/utils/api";
import { getMe } from "@/utils/me";

interface Props {
  cancel?: () => void;
  setIsAddCareer: (arg0: boolean) => void;
  profileSubmit?: () => void;
  bgColor: string;
  isProfile: boolean;
}

export const EditCareer = (props: Props) => {
  useControlBlowserBack();
  const navigate = useNavigate();
  const [, setKey] = useRecoilState(locationState);
  const [user, setUser] = useRecoilState(userSelector);
  const [career, setCareer] = useRecoilState(CareerSelector);
  const [isDelete, setIsDelete] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formValidations, setFormValidations] = useState<string[]>([]);
  const form: JSX.Element[] = [];
  const ref = useRef<HTMLDivElement>(null);
  const { error } = useToast();

  const handleLaterUpload = () => {
    setKey((prevValue) => ({ ...prevValue, path: "/register/desired-condition" }));
    navigate("/register/desired-condition");
  };

  const handleAddForm = () => {
    setCareer((prevValue) => ({ ...prevValue, formInd: career.formInd + 1 }));
  };

  const handleCancel = () => {
    props.setIsAddCareer(false);
  };

  const handleDelete = async () => {
    setKey((prevValue) => ({ ...prevValue, isLoading: true }));
    const res = await apiDelete("/api/user/desired-condition/" + user.user_careers[career.formInd - 1].id);
    if (res.code === 200) {
      setCareer((prevValue) => ({ ...prevValue, formInd: career.formInd - 1 }));
      setIsDelete(false);
      getMe().then((user) => {
        user && setUser(user as User);
      });
      props.setIsAddCareer(false);
    } else {
      error(res.message);
    }
    setKey((prevValue) => ({ ...prevValue, isLoading: false }));
  };

  const handleFormValidation = (index: number) => {
    setFormValidations((prevValidations) => {
      const newValidations = [...prevValidations];
      newValidations[index] = "valid";
      return newValidations;
    });
  };

  useEffect(() => {
    // 全フォームのバリデーション通過時のみリロード
    const booleanValidations = formValidations.filter((validation) => validation !== "empty");
    if (booleanValidations.length === career.formInd) {
      getMe().then((user) => {
        user && setUser(user as User);
      });
      props.setIsAddCareer(false);
    }
  }, [formValidations]);

  useEffect(() => {
    if (!isDelete) {
      return;
    }
    if (career.formInd !== user.user_careers.length) {
      // storeから削除
      setCareer((prevValue) => ({ ...prevValue, formInd: career.formInd - 1 }));
      setIsDelete(false);
      return;
    }
    // dbから削除
    handleDelete();
  }, [isDelete]);

  useEffect(() => {
    if (isSubmit) {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    // 職歴のインデックス初期値設定
    const formIndex = user.user_careers.length === 0 ? 1 : user.user_careers.length;
    setCareer((prevValue) => ({ ...prevValue, formInd: formIndex }));
  }, []);

  return (
    <div className={`form-group mb-6 p-6 ${props.bgColor}`} ref={ref}>
      <Loading />

      <div className='mb-6 mr-1'>
        {!props.isProfile && (
          <label htmlFor='current-company' className='ml-6 text-[14px] font-bold'>
            職歴
          </label>
        )}
        {(() => {
          for (let i = 0; i < career.formInd; i++) {
            form.push(
              <p>
                {i !== 0 ? <div className='w-full border-b'></div> : ""}
                {
                  <CareerForm
                    setIsAddCareer={props.setIsAddCareer}
                    bgColor={props.bgColor}
                    isProfile={props.isProfile}
                    ind={i}
                    setIsDelete={setIsDelete}
                    isSubmit={isSubmit}
                    setIsSubmit={setIsSubmit}
                    handleFormValidation={handleFormValidation}
                  />
                }
              </p>,
            );
          }
          return form;
        })()}
        <button
          type='submit'
          className='mb-8 w-full border-spacing-1 rounded-md border py-3 text-center text-[13px] font-bold text-blue-700 hover:bg-blue-100 focus:outline-none'
          onClick={handleAddForm}
        >
          ＋&nbsp;職歴を追加する
        </button>
        {props.isProfile ? (
          <div className='flex justify-end gap-[16px] text-[13px]'>
            <button
              type='reset'
              className='my-1 h-[40px] w-[144px] py-3 text-center font-bold leading-5 text-blue-700 focus:outline-none'
              onClick={handleCancel}
            >
              キャンセル
            </button>
            <button
              type='submit'
              className={`} my-1 h-[40px] w-[144px] rounded-[4px] bg-blue-700 py-2 text-center font-bold leading-5 text-white 
              focus:outline-none`}
              onClick={() => {
                setIsSubmit(true);
              }}
            >
              保存する
            </button>
          </div>
        ) : (
          <div className='text-center'>
            <div className='md:flex'>
              <button
                type='submit'
                className='w-full rounded-full border border-blue-700 py-3 text-center text-[13px] font-bold text-blue-700 hover:bg-blue-100 focus:outline-none sm:my-2 md:m-2'
                onClick={handleLaterUpload}
              >
                入力をスキップする
              </button>
              <button
                type='submit'
                className='w-full rounded-full bg-blue-700 py-3 text-center text-[13px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:my-2 md:m-2'
                onClick={() => {
                  setIsSubmit(true);
                }}
              >
                次へ
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCareer;
