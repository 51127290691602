import { useState } from "react";

import { useRecoilState } from "recoil";

import useMediaQuery from "@/hooks/useMediaQuery";
import { locationState } from "@/stores/atoms/locationAtom";

interface TabProps {
  label: string;
  active: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ label, active, onClick }) => {
  const isSm = useMediaQuery();
  const colorStyle = active
    ? {
        background: `linear-gradient(to right, #2c3c6c, #405494)`,
      }
    : {};
  const activeClasses = active
    ? "text-gray-200 bg-blue-900 border font-bold border-[#3c447c]"
    : "text-gray-600 border font-bold border-gray-200 bg-white";
  const positionClass = label === "複業を探す" || label === "複業" ? "rounded-l-lg" : "rounded-r-lg";
  const triangleStyle = active
    ? {
        width: "10px",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "10px solid #3c447c",
        marginLeft: isSm ? "20px" : "42px",
      }
    : {};

  return (
    <div>
      <div
        className={`md:text-md sm:leading-1 flex cursor-pointer text-center sm:w-[60px] sm:px-3 sm:py-1 sm:text-[11px] md:w-full md:px-4 md:py-3 md:text-[14px] md:leading-4 ${activeClasses} ${positionClass}`}
        style={colorStyle}
        onClick={onClick}
      >
        {label}
      </div>
      {!isSm && <div className='w-10' style={triangleStyle}></div>}
    </div>
  );
};

const TabContainer: React.FC = () => {
  const [key, setKey] = useRecoilState(locationState);
  const [activeTab, setActiveTab] = useState<string>(key.tab);
  const isSm = useMediaQuery();

  const handleTabClick = (tab: string) => {
    const isNumericId = key.path.match(/[0-9]$/);
    if (isNumericId) {
      setActiveTab(key.tab);
      setKey((prevValue) => ({ ...prevValue, tab: key.tab }));
    } else {
      setActiveTab(tab);
      setKey((prevValue) => ({ ...prevValue, tab: tab }));
    }
  };

  return (
    <div className='z-50 mx-0 flex min-w-[210px] justify-start pb-1 font-sans sm:min-w-[120px]'>
      <Tab label={"複業を探す"} active={activeTab === "side-job"} onClick={() => handleTabClick("side-job")} />
      <Tab
        label={"転職を探す"}
        active={activeTab === "career-change"}
        onClick={() => handleTabClick("career-change")}
      />
    </div>
  );
};

export default TabContainer;
