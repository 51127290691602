import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import JobListItem from "@/components/layouts/top/JobListItem";
import useAuth from "@/hooks/useAuth";
import useToast from "@/hooks/useToast";
import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { JobList } from "@/types/job";
import { apiGet } from "@/utils/api";

function TopPage() {
  const { error } = useToast();
  const [key, setKey] = useRecoilState(locationState);
  const [jobs, setJobs] = useState<JobList[]>([]);
  const { isReady } = useAuth();
  const [user] = useRecoilState(userSelector);

  useEffect(() => {
    key.component !== "withdraw" && setKey((prevValue) => ({ ...prevValue, component: "" }));
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await apiGet(key.tab === "side-job" ? "/api/offers?type=1" : "/api/offers?type=2");
      if ("data" in res) {
        setKey((prevValue) => ({ ...prevValue, path: "" }));
        setJobs(res.data as JobList[]);
      } else {
        error(res.message);
      }
    };
    fetchJobs();
  }, [key.tab]);

  if (!isReady) return <></>;

  return (
    <div className='overflow-x-hidden font-sans'>
      {/* ログインユーザーのみ表示 */}
      {(user.first_name !== "" || user.last_name !== "") && (
      <div className='flex flex-col w-screen items-center justify-center bg-blue-100 p-4 py-8 pt-8 mb-2 mt-2 text-[#2c3c6c]'>
        <div className="flex flex-col">
          <span className="text-xl">あなたのキャリアに合わせた求人（副業・転職）を専門コンサルタントがご提案</span>
          <span className='text-xs'>※非公開の求人もご紹介できる場合があります</span>
        </div>
        <a className="font-bold text-xl pt-3 underline after:content-[''] after:inline-block after:ml-2 after:w-3 after:h-3 after:bg-[url('/public/images/external-link-icon.png')] after:bg-contain after:bg-no-repeat" href="https://app.spirinc.com/t/hSpBAsmos7UEsp0gW6nAD/as/iLlIxQAhhqcbfBjk4998K/confirm" target="_blank" rel="noopener noreferrer">
          キャリア面談のご予約はこちら
        </a>
      </div>
      )}
      <div className='flex w-screen items-center justify-center bg-blue-100 p-4 py-10 pt-12 text-center text-[24px]'>
        新着求人
      </div>
      <div className='flex justify-center bg-blue-100 md:px-12'>
        <div className='flex max-w-[1600px] items-center justify-center pb-[40px] sm:w-screen md:w-full'>
          <div className='grid grid-cols-12'>
            {jobs.map((job) => (
              <div
                key={job.id}
                className='flex justify-center sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-4'
              >
                <JobListItem {...(job as JobList)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPage;
