import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";
import { userSelector } from "@/stores/selectors/userSelector";
import { PathMap, RequiredRegisterStep } from "@/utils/path";

const useRedirectStep = () => {
  const navigate = useNavigate();
  const [user] = useRecoilState(userSelector);
  const [, setKey] = useRecoilState(locationState);
  useEffect(() => {
    const isRegistering = user.register_step === 0 || (user.register_step && user.register_step < RequiredRegisterStep);
    const shouldRedirect = isRegistering && !location.pathname.includes("register");
    if (!shouldRedirect) return;

    const nextStepPath = `/register/${PathMap.get((user.register_step ?? 0) + 1)}`;
    setKey((prevValue) => ({ ...prevValue, path: nextStepPath }));
    navigate(nextStepPath);
  }, [user, location.pathname]);
};

export default useRedirectStep;
