import { Outlet, BrowserRouter, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

import "react-toastify/dist/ReactToastify.css";
import useAuth from "./hooks/useAuth";

import FooterLayout from "@/components/layouts/common/Footerlayout";
import HeaderLayout from "@/components/layouts/common/HeaderLayout";
import CompanyPage from "@/components/pages/footer/CompanyPage";
import CompletePage from "@/components/pages/footer/CompletePage";
import NdaAgreePage from "@/components/pages/footer/NdaAgreePage";
import NdaPage from "@/components/pages/footer/NdaPage";
import PrivacyPage from "@/components/pages/footer/PrivacyPage";
import TermPage from "@/components/pages/footer/TermPage";
import TokuteiPage from "@/components/pages/footer/TokuteiPage";
import ToriatsukaiPage from "@/components/pages/footer/ToriatsukaiPage";
import JobsPage from "@/components/pages/jobs/JpbsPage";
import LoginPage from "@/components/pages/login/LoginPage";
import ReminderPasswordPage from "@/components/pages/login/ReminderPasswordPage";
import ResetPasswordPage from "@/components/pages/login/ResetPasswordPage";
import Lp02 from "@/components/pages/lp/Lp02";
import LpSideJob from "@/components/pages/lp/LpSideJob";
import ProfilePage from "@/components/pages/profile/ProfilePage";
import AnnualIncomePage from "@/components/pages/Register/AnnualIncomePage";
import BirthdayPage from "@/components/pages/Register/BirthdayPage";
import CareerPage from "@/components/pages/Register/CareerPage";
import ConditionsPage from "@/components/pages/Register/ConditionsPage";
import CurrentCompanyPage from "@/components/pages/Register/CurrentCompanyPage";
import CurrentIndustryPage from "@/components/pages/Register/CurrentIndustryPage";
import CurrentJobPage from "@/components/pages/Register/CurrentJobPage";
import DesireIndustryPage from "@/components/pages/Register/DesireIndustryPage";
import DesireJobPage from "@/components/pages/Register/DesireJobPage";
import EmailPage from "@/components/pages/Register/EmailPage";
import GenderPage from "@/components/pages/Register/GenderPage";
import LastEducationPage from "@/components/pages/Register/LastEducationPage";
import Legal from "@/components/pages/Register/Legal";
import PasswordPage from "@/components/pages/Register/PasswordPage";
import PostalCodePage from "@/components/pages/Register/PostalCodePage";
import RegisterPage from "@/components/pages/Register/RegisterPage";
import ResumePage from "@/components/pages/Register/ResumePage";
import SmsPage from "@/components/pages/Register/SmsPage";
import WillingnessPage from "@/components/pages/Register/WillingnessPage";
import ServicePage from "@/components/pages/ServicePage";
import SettingPage from "@/components/pages/settings/SettingPage";
import TopPage from "@/components/pages/TopPage";

import "@/App.css";

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <ToastContainer />
        <HeaderLayout />
        <Routes>
          <Route element={<AuthWrapper />}>
            <Route path='/tl/01' Component={LpSideJob} />
            <Route path='/tl/02' Component={Lp02} />
            <Route path='/' Component={TopPage} />
            <Route path='/login' Component={LoginPage} />
            <Route path='/reminder/password' Component={ReminderPasswordPage} />
            <Route path='/reset/password' Component={ResetPasswordPage} />
            <Route path='/register/*' Component={RegisterPage} />
            <Route path='/register/sms' Component={SmsPage} />
            <Route path='/register/email' Component={EmailPage} />
            <Route path='/register/password' Component={PasswordPage} />
            <Route path='/register/gender' Component={GenderPage} />
            <Route path='/register/birthday' Component={BirthdayPage} />
            <Route path='/register/postal-code' Component={PostalCodePage} />
            <Route path='/register/annual-income' Component={AnnualIncomePage} />
            <Route path='/register/last-education' Component={LastEducationPage} />
            <Route path='/register/current-industry' Component={CurrentIndustryPage} />
            <Route path='/register/current-job' Component={CurrentJobPage} />
            <Route path='/register/current-company' Component={CurrentCompanyPage} />
            <Route path='/register/resume' Component={ResumePage} />
            <Route path='/register/career' Component={CareerPage} />
            <Route path='/register/desired-condition' Component={ConditionsPage} />
            <Route path='/register/willingness' Component={WillingnessPage} />
            <Route path='/register/legal' Component={Legal} />
            <Route path='/profile/' Component={ProfilePage} />
            <Route path='/Jobs/:id' Component={JobsPage} />
            <Route path='/settings' Component={SettingPage} />
            <Route path='/register/desired-industry' Component={DesireIndustryPage} />
            <Route path='/register/desired-job' Component={DesireJobPage} />
            <Route path='/company/' Component={CompanyPage} />
            <Route path='/term/' Component={TermPage} />
            <Route path='/privacy/' Component={PrivacyPage} />
            <Route path='/tokutei/' Component={TokuteiPage} />
            <Route path='/toriatsukai/' Component={ToriatsukaiPage} />
            <Route path='/nda/' Component={NdaPage} />
            <Route path='/nda/agree' Component={NdaAgreePage} />
            <Route path='/nda/complete' Component={CompletePage} />
            <Route path='/service' Component={ServicePage} />
          </Route>
        </Routes>
        <FooterLayout />
      </RecoilRoot>
    </BrowserRouter>
  );
}

function AuthWrapper() {
  useAuth();
  return <Outlet />;
}

window.dataLayer = window.dataLayer || [];

export default App;
