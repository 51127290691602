import useMediaQuery from "@/hooks/useMediaQuery";
import useToast from "@/hooks/useToast";
import { apiPost } from "@/utils/api";

interface Props {
  offer_id: number;
  title: string;
  is_applied: boolean;
}
function Apply(props: Props) {
  const isSm = useMediaQuery();
  const { error, success } = useToast();

  const handleApply = async () => {
    const params = {
      offer_id: props.offer_id,
    };
    const res = await apiPost("/api/apply/", params);
    if (res.code === 200) {
      success("募集に応募しました");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      error(res.message);
    }
  };

  return (
    <div className='fixed bottom-0 left-0 w-screen items-center bg-white shadow-2xl sm:py-2 md:flex md:py-2'>
      {!isSm && <p className='ml-20 w-4/6 break-all px-14 text-[16px] font-bold'>{props.title}</p>}
      <div className='flex items-center sm:px-4 md:w-2/6'>
        {!props.is_applied ? (
          <button
            onClick={handleApply}
            className='mx-1 hidden h-fit rounded-md border border-blue-700 bg-blue-700 py-2 text-center text-[16px] font-bold text-white hover:bg-blue-500 focus:outline-none sm:block sm:w-full md:w-2/3'
          >
            応募する
          </button>
        ) : (
          <button
            className='mx-1 hidden rounded-md bg-gray-400 py-2 text-center text-[16px] font-bold text-white sm:block sm:w-full md:w-1/3'
            disabled
          >
            応募済み
          </button>
        )}
        {/* {isSm ? (
          <button className='mx-1 hidden h-fit rounded-md border bg-green-500 py-2 text-center text-[16px] font-bold text-white hover:bg-green-300 focus:outline-none sm:block sm:w-full md:w-2/3'>
            LINEで問い合わせ
          </button>
        ) : (
          <div className='ml-4 w-fit bg-gray-200 p-2 font-bold text-white'>QR</div>
        )} */}
      </div>
    </div>
  );
}

export default Apply;
